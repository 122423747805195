<script setup lang="ts">
import { useWindowScroll, watchDebounced, watchThrottled } from '@vueuse/core';
const { $cathodeClient, $eventClient } = useNuxtApp();
import { vElementVisibility } from '@vueuse/components';
import { CategoryCard } from '@laam/ui/cms';
import type { LinkNode } from '@laam/cms-shared';

interface CategoryCardProps {
	redirect: string;
	horizontalIndex: number;
	verticalIndex: number;
	data: LinkNode;
	dataSource: {
		dataSource: string;
		version: string | undefined;
		clickedFrom: string;
	};
	shape: 'square' | 'rectangle';
}

const props = defineProps<CategoryCardProps>();

const { y } = useWindowScroll();
const hasWindowScrolled = ref(false);
watchThrottled(
	y,
	() => {
		hasWindowScrolled.value = true;
	},
	{ throttle: 200 },
);

function handleClickEvent() {
	$cathodeClient.queueEvent('entity_click', {
		source: window.location.href,
		page: 'homepage',
		identifier: props.redirect,
		clicked_from:
			props.dataSource.clickedFrom === 'category-listing'
				? 'category-listing'
				: 'homepage',
		entity: 'node',
		horizontal_index: props.horizontalIndex,
		vertical_index: props.verticalIndex,
		slotted: false,
		heading: props.data.name,
		component: 'categories',
		product_count: props.data.product_count || null,
		sale_percentage: 0,
		scroll_position: y.value,
		data_source: `${props.dataSource.dataSource} - ${props.dataSource.version}`,
		data_string: JSON.stringify(props.data),
	});
	if (props.dataSource.clickedFrom === 'homepage') {
		$eventClient.sendEvent('homepage-carousel-click', {
			to: props.redirect,
			index: props.verticalIndex,
			title: 'Shop by Category',
		});
	} else {
		$eventClient.sendEvent('category-clicked', {
			source: window.location.href,
			categoryLink: props.redirect,
			categoryName: props.data.name,
		});
	}
}

const isVisible = ref(false);
function onElementVisibility(state: boolean) {
	isVisible.value = state;
}
watchDebounced(
	isVisible,
	(value) => {
		if (value) {
			$cathodeClient.queueImpression('entity_impression', {
				data: [
					{
						identifier: props.redirect,
						product_count: props.data.product_count || null,
						sale_percentage: 0,
						slotted: false,
						horizontal_index: props.horizontalIndex,
						data_string: JSON.stringify(props.data),
					},
				],
				vertical_index: props.verticalIndex,
				entity: 'node',
				heading: props.data.name,
				component: 'categories',
				scroll_position: y.value,
				source: window.location.href,
				type: hasWindowScrolled.value ? 'scroll_impression' : 'load_impression',
				time: new Date().toUTCString(),
				data_source: `${props.dataSource.dataSource} - ${props.dataSource.version}`,
				clicked_from: 'category-listing',
				page: 'category-listing',
			});
		}
	},
	{
		debounce: useRuntimeConfig().public.impressionDebounceTime,
	},
);
defineOptions({ inheritAttrs: false });
</script>

<template>
	<NuxtLink :to="redirect" class="block w-full" @click="handleClickEvent()">
		<CategoryCard
			v-element-visibility="[onElementVisibility, { threshold: 0.8 }]"
			:data="data"
			:shape="shape"
			v-bind="$attrs"
		/>
	</NuxtLink>
</template>

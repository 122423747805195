<script setup lang="ts">
import { type EntityData, type LinkNode } from '@laam/cms-shared';
import CategoryCard from './CategoryCard.vue';
import { computed, inject, ref, type Ref } from 'vue';
import { IconButton } from '../base';
import { PhCaretLeft, PhCaretRight } from '@phosphor-icons/vue';
import Nov11SaleIcon from '../../icons/Nov11SaleIcon.vue';

interface Props {
	metadata: EntityData;
	data: LinkNode[];
	sectionIndex: number;
}

const { data, metadata } = defineProps<Props>();

const numberOfColumns = computed(() => {
	return Math.ceil(data.length / 2);
});

const categoryCarouselRef = ref();

const columns = computed(() => {
	const columns = Array.from(
		{ length: numberOfColumns.value },
		() => [],
	) as Array<Array<LinkNode>>;
	data.forEach((item, index) => {
		const columnIndex = Math.floor(index / 2);
		columns[columnIndex]!.push(item);
	});
	return columns;
});

const getIndex = (horizontalIndex: number, verticalIndex: number) => {
	if (verticalIndex === 0) {
		return horizontalIndex * 2;
	} else {
		return verticalIndex + horizontalIndex * 2;
	}
};

const handleNext = () => {
	categoryCarouselRef.value?.next();
};
const handlePrev = () => {
	categoryCarouselRef.value?.prev();
};

const maxSlide = computed(() => {
	return categoryCarouselRef.value?.data.maxSlide.value;
});

const currentSlide = computed(() => {
	return categoryCarouselRef.value?.data.currentSlide.value;
});

const isPrevDisabled = computed(() => {
	return currentSlide.value < 1;
});

const isNextDisabled = computed(() => {
	return currentSlide.value >= maxSlide.value;
});

const deviceType = inject('deviceType') as Ref<string | undefined>;

const showNov11Sale = inject('showNov11Sale') as boolean;
</script>

<template>
	<div class="gap-3xl flex flex-col items-center w-full px-3xl lg:px-0">
		<div class="gap-sm flex w-full justify-between items-center">
			<div class="gap-sm flex flex-col w-full items-start">
				<h1
					ref="title"
					class="text-lg font-semibold lg:display-xs text-gray-900"
				>
					{{ metadata.title }}
				</h1>
				<div v-if="showNov11Sale" class="flex gap-sm">
					<Nov11SaleIcon />
					<h1 class="text-sm font-normal text-gray-600">Exclusive</h1>
				</div>
			</div>
			<div v-if="deviceType !== 'mobile'" class="flex gap-xs pr-7xl">
				<IconButton
					variant="secondary"
					size="xs"
					rounded="full"
					:disabled="isPrevDisabled"
					@click="handlePrev"
				>
					<PhCaretLeft />
				</IconButton>
				<IconButton
					variant="secondary"
					size="xs"
					rounded="full"
					:disabled="isNextDisabled"
					@click="handleNext"
				>
					<PhCaretRight />
				</IconButton>
			</div>
		</div>
		<Carousel
			ref="categoryCarouselRef"
			class="cms--grid-categories-scrollable w-full"
			:items-to-show="deviceType === 'mobile' ? 3.2 : 6"
			snap-align="start"
		>
			<Slide
				v-for="(column, horizontalIndex) in columns"
				:key="horizontalIndex"
				:index="horizontalIndex"
			>
				<div class="flex flex-col gap-7xl w-full">
					<template
						v-for="(node, verticalIndex) in column"
						:key="horizontalIndex + verticalIndex"
					>
						<slot
							:entity="metadata.entity"
							:data="node"
							:index="getIndex(horizontalIndex, verticalIndex)"
						>
							<CategoryCard
								:entity="metadata.entity"
								:data="node"
								:index="horizontalIndex"
								shape="square"
							/>
						</slot>
					</template>
				</div>
			</Slide>
		</Carousel>
	</div>
</template>

<style>
.cms--grid-categories-scrollable .carousel__prev {
	@apply bg-white rounded-full top-[40%] translate-y-[-40%]  mx-3xl lg:mx-5xl [&_svg]:!size-3xl;
}

.cms--grid-categories-scrollable .carousel__next {
	@apply bg-white rounded-full top-[40%] translate-y-[-40%]  mx-3xl lg:mx-5xl [&_svg]:!size-3xl;
}

.cms--grid-categories-scrollable .cms--grid-categories-scrollable__item {
	@apply relative rounded-xs text-xl text-white bg-white w-full;
}

.cms--grid-categories-scrollable .carousel__slide {
	@apply pr-xl lg:pr-7xl items-start;
}

.cms--grid-categories-scrollable .carousel__pagination {
	@apply absolute mx-auto left-[0px] right-[0px] gap-sm w-fit bottom-3xl;
}

.cms--grid-categories-scrollable .carousel__pagination-item {
	@apply box-border;
}

.cms--grid-categories-scrollable .carousel__pagination-button {
	@apply p-none;
}

.cms--grid-categories-scrollable .carousel__pagination-button::after {
	@apply bg-white rounded-2xl w-10xl h-xs opacity-40;
}

.cms--grid-categories-scrollable .carousel__pagination-button--active::after {
	@apply bg-white opacity-100;
}
</style>

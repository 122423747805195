<script setup lang="ts">
import type { HeroBannerItem } from '@laam/cms-shared';
import { useWindowScroll, watchDebounced, watchOnce } from '@vueuse/core';
import LazyImage from '~/components/LazyImage.vue';
import { vElementVisibility } from '@vueuse/components';
import { cn } from '@laam/lib/utils';
import { parseDate } from '@internationalized/date';
import { isMobileView } from '~/utils/helpers';

interface Props {
	data: HeroBannerItem;
	sectionIndex?: number;
	displayTimer?: boolean;
	endTime?: string;
}

const { data, sectionIndex, displayTimer, endTime } = defineProps<Props>();

const { $cathodeClient, $eventClient } = useNuxtApp();

const { deviceType } = useDeviceType();

const imageUrl = computed(() => {
	return deviceType.value === 'desktop' ? data.desktop_url : data.mobile_url;
});

const entity = ref('');

const isVisible = ref(false);

const { y } = useWindowScroll();
const hasWindowScrolled = ref(false);
watchOnce(y, () => {
	hasWindowScrolled.value = true;
});

function onElementVisibility(state: boolean) {
	isVisible.value = state;
}

const targetDate = computed(() => {
	if (!endTime) {
		return null;
	} else {
		const timeParts = parseDate(endTime);
		return new Date(
			timeParts.year,
			timeParts.month - 1,
			timeParts.day,
			23,
			59,
			59,
		);
	}
});

const timeLeft = ref<{
	days: string;
	hours: string;
	minutes: string;
	seconds: string;
}>({
	days: '-',
	hours: '--',
	minutes: '--',
	seconds: '--',
});

const difference = inject('difference') as Ref<number>;

const updateTimeLeft = () => {
	if (!targetDate.value) return;

	const now = new Date();
	difference.value = targetDate.value.getTime() - now.getTime();

	if (difference.value <= 0) {
		timeLeft.value = {
			days: '0',
			hours: '0h',
			minutes: '0m',
			seconds: '0s',
		};
	} else {
		const days = Math.floor(
			difference.value / (1000 * 60 * 60 * 24),
		).toString();
		const hours = Math.floor(
			(difference.value % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
		).toString();
		const minutes = Math.floor(
			(difference.value % (1000 * 60 * 60)) / (1000 * 60),
		).toString();
		const seconds = Math.floor(
			(difference.value % (1000 * 60)) / 1000,
		).toString();

		timeLeft.value = {
			days: days,
			hours: hours + 'h',
			minutes: minutes + 'm',
			seconds: seconds + 's',
		};
	}
};

const timeLeftWithoutDays = computed(() => {
	const { hours, minutes, seconds } = timeLeft.value;
	return { hours, minutes, seconds };
});

onMounted(() => {
	updateTimeLeft();
	const timeInterval = setInterval(updateTimeLeft, 1000);
	onBeforeUnmount(() => {
		clearInterval(timeInterval);
	});
});

watchDebounced(
	isVisible,
	(value) => {
		if (value) {
			$cathodeClient.queueImpression('entity_impression', {
				data: [
					{
						identifier: data.link.href.split('/')[2],
						data_string: JSON.stringify(data),
					},
				],
				vertical_index: sectionIndex!,
				entity: entity.value as 'node' | 'brand' | 'drop',
				heading: '',
				component: 'mini-banner',
				scroll_position: y.value,
				source: window.location.href,
				type: hasWindowScrolled.value ? 'scroll_impression' : 'load_impression',
				time: new Date().toUTCString(),
				data_source: 'manual',
				clicked_from: 'homepage',
				page: 'homepage',
			});
		}
	},
	{
		debounce: useRuntimeConfig().public.impressionDebounceTime,
	},
);

const collectionToNode = (url: string) => {
	if (displayTimer === true) {
		url = '/';
	}
	if (url.includes('collections')) {
		entity.value = 'node';
		return '/nodes' + url.split('collections')[1];
	} else {
		if (url.includes('brands')) {
			entity.value = 'brand';
		} else if (url.includes('drops')) {
			entity.value = 'drop';
		} else if (url.includes('nodes')) {
			entity.value = 'node';
		}
		return url;
	}
};

function handleClickEvent() {
	// Cathode event
	$cathodeClient.queueEvent('entity_click', {
		source: window.location.href,
		page: 'homepage',
		identifier: data.link.href.split('/')[2]!,
		clicked_from: 'homepage',
		entity: entity.value as 'node' | 'brand' | 'drop',
		horizontal_index: 0,
		vertical_index: sectionIndex!,
		slotted: false,
		heading: '',
		component: 'mini-banner',
		product_count: null,
		sale_percentage: 0,
		scroll_position: y.value,
		data_source: 'manual',
		data_string: JSON.stringify(data),
	});
	// statsig event
	$eventClient.sendEvent('homepage-carousel-click', {
		to: data.link.href,
		index: sectionIndex!,
		title: 'mini-banner',
	});
}
</script>
<template>
	<div>
		<NuxtLink
			v-element-visibility="[onElementVisibility, { threshold: 0.8 }]"
			:to="collectionToNode(data.link.href)"
			as="button"
			:disabled="data.link.href === 'https://laam.pk'"
			:class="
				cn('block w-full lg:rounded-3xl', {
					relative: displayTimer,
				})
			"
			@click="handleClickEvent()"
		>
			<LazyImage
				class="homepage__image-slider-image lg:rounded-3xl"
				:src="imageUrl"
				:alt="imageUrl"
				:width="deviceType === 'mobile' ? 250 : 1032"
				loading="eager"
			/>
			<div
				v-if="displayTimer"
				class="md:max-h-w-[500px] md:w-[330px] flex flex-row gap-xs absolute md:top-[20px] right-0 md:mr-[20px] md:float-right"
				:class="{
					'top-[60%] !w-full justify-center ': isMobileView(),
					'!w-[350px]': timeLeft.days > '1',
				}"
			>
				<div
					class="px-md py-[0.20rem] bg-white rounded-small flex justify-center items-center"
				>
					<p
						v-if="timeLeft.days === '0'"
						class="text-semibold md:text-xl text-center text-error-800 font-semibold"
						:class="{ 'text-md': isMobileView() }"
					>
						Ends in
					</p>
					<p
						v-else
						class="text-semibold md:text-xl text-center text-error-800 font-semibold"
						:class="{ 'text-md': isMobileView() }"
					>
						Ends in {{ timeLeft.days }}
						{{ timeLeft.days < '2' ? 'day' : 'days' }}
					</p>
				</div>
				<div
					v-for="(value, unit) in timeLeftWithoutDays"
					:key="unit"
					class="flex items-center gap-xs"
				>
					<p class="text-white text-xl">:</p>
					<div
						class="md:w-[48px] md:h-[38px] bg-white rounded-small py-sm px-xxs flex justify-center items-center"
						:class="{ 'h-[32px] w-[40px]': isMobileView() }"
					>
						<span
							class="text-md font-semibold text-error-800 text-center md:text-xl sm:text-md"
						>
							{{ value }}
						</span>
					</div>
				</div>
			</div>
		</NuxtLink>
	</div>
</template>
